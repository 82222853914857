//         下标 当前选项组 总体选项组
function bqxz(e, small, big) {
    //声明一个变量来判断此时下选项的状态
    let c = 0;
    //声明一个变量来保存上一个点击的选项
    let cun = '';
    //循环判断总体选项组中有没有当前选项组的成员
    for (let i = 0; i < small.length; i++) {
        for (let j = 0; j < big.length; j++) {
            //如果有的话就记录下来
            if (small[i].cnVal == big[j]) {
                c = 1;
                cun = small[i].cnVal;
                break;
            }
        }
    }
    //第n次点击选择
    if (c == 1) {
        //找到上一次选择的选项
        let index = big.map((item) => item).indexOf(cun);
        //删除
        big.splice(index, 1);
        //再添加这一次的
        big.push(small[e].cnVal);
        //判断是否与上一次的一样，如果是一样的那么就是点击让它取消
        if (cun == small[e].cnVal) {
            //那么找到这一次的，然后删除
            let index1 = big.map((item) => item).indexOf(small[e].cnVal);
            big.splice(index1, 1);
            return 1;
        }
    } else {
        //第一次点击直接push
        big.push(small[e].cnVal);
    }
}
export { bqxz };
