<template>
    <div class="top">
        <p class="yx">
            已选条件
            <span v-for="(item, index) in yxx" :key="index">{{ item }}</span>
        </p>
        <p v-if="labelList.includes('客户资质')">
            客户资质
            <span
                v-for="(item, index) in zz"
                :key="index"
                :class="{ active: item.beal }"
                @click="zzk(index)"
            >{{ item.cnVal }}</span>
        </p>
        <p v-if="labelList.includes('资金需求')">
            资金需求
            <span
                v-for="(item, index) in dked"
                :key="index"
                :class="{ active: index == isShow1 }"
                @click="dk(index)"
            >{{ item.cnVal }}</span>
        </p>
        <p v-if="labelList.includes('客户状态')">
            客户状态
            <span
                v-for="(item, index) in wqr"
                :key="index"
                :class="{ active: index == isShow2 }"
                @click="qw(index)"
            >{{ item.cnVal }}</span>
        </p>
        <p v-if="labelList.includes('获得方式')">
            获得方式
            <span
                v-for="(item, index) in hdfs"
                :key="index"
                :class="{ active: index == isShow3 }"
                @click="hd(index)"
            >{{ item.cnVal }}</span>
        </p>
        <p v-if="labelList.includes('未确认标签')">
            未确认标签
            <span
                v-for="(item, index) in bq"
                :key="index"
                :class="{ active: index == isShow4 }"
                @click="bqsj(index)"
            >{{ item.cnVal }}</span>
        </p>
        <p v-if="labelList.includes('线索状态')">
            线索状态
            <span
                v-for="(item, index) in xszt"
                :key="index"
                :class="{ active: index == isShow5 }"
                @click="zt(index)"
            >{{ item.cnVal }}</span>
        </p>
        <p v-if="labelList.includes('无效标签')">
            无效标签
            <span
                v-for="(item, index) in wxbq"
                :key="index"
                :class="{ active: index == isShow6 }"
                @click="wx(index)"
            >{{ item.cnVal }}</span>
        </p>
    </div>
</template>
<script>
import { bqxz } from '../../assets/js/select.js';
// import { c_values } from "../../api/public_content.js";
export default {
    props: ['labelList'],
    name: 'LabelList',
    data() {
        return {
            yxx: [],
            zz: [],
            dked: [],
            wqr: [],
            shouji: [],
            hdfs: [],
            bq: [],
            xszt: [],
            wxbq: [],
            isShow1: NaN,
            isShow2: NaN,
            isShow3: NaN,
            isShow4: NaN,
            isShow5: NaN,
            isShow6: NaN,
            information: {
                staffId: null,
                qualityType: null,
                fundNeedsScopeType: null,
                followStage: null,
                getCategory: null,
                unconfirmedTag: null,
                status: null,
                invalidTag: null,
            },
        };
    },
    mounted() {
        let quality = this.common.allValueNeed('loan-quality-type');
        this.zz = quality;
        for (let i = 0; i < this.zz.length; i++) {
            this.zz[i].beal = false;
        }
        //未确认标签
        this.wqr = this.common.allValueNeed('client-follow-stage');
        //资金需求范围类型
        this.dked = this.common.allValueNeed('fund-needs-scope-type');
        //客户获取方式
        this.hdfs = this.common.allValueNeed('client-get-category');
        //线索未确认标签
        this.bq = this.common.allValueNeed('clue-unconfirmed-tag');
        //线索状态
        this.xszt = this.common.allValueNeed('clue-status');
        //线索无效标签
        this.wxbq = this.common.allValueNeed('clue-invalid-tag');
        //获取字典代码
        // c_values({ dictionaryCode: "loan-quality-type" }).then((res) => {
        //   this.zz = res.data.list;
        //   for (let i = 0; i < this.zz.length; i++) {
        //     this.zz[i].beal = false;
        //   }
        // });
        // this.wqr = this.$dictionary.stage;
        // this.dked = this.$dictionary.needs;
        // this.hdfs = this.$dictionary.category;
        // this.bq = this.$dictionary.unconfirmed;
        // this.xszt = this.$dictionary.status;
        // this.wxbq = this.$dictionary.tag;
    },
    methods: {
        parentMsg() {
            this.isShow1 = NaN;
            this.isShow2 = NaN;
            this.isShow3 = NaN;
            this.isShow4 = NaN;
            this.isShow5 = NaN;
            this.isShow6 = NaN;
            for (let i = 0; i < this.zz.length; i++) {
                this.zz[i].beal = false;
            }
            this.yxx = [];
            this.shouji = [];
            this.information = {
                staffId: null,
                qualityType: null,
                fundNeedsScopeType: null,
                followStage: null,
                getCategory: null,
                unconfirmedTag: null,
                status: null,
                invalidTag: null,
            };
        },
        //客户资质标签选择
        zzk(e) {
            this.zz[e].beal = !this.zz[e].beal;
            if (this.zz[e].beal == true) {
                this.yxx.push(this.zz[e].cnVal);
                this.shouji.push(this.zz[e].number);
                this.information.qualityType = this.shouji.join(',');
            } else {
                let index = this.yxx
                    .map((item) => item)
                    .indexOf(this.zz[e].cnVal);
                let index1 = this.shouji.indexOf(this.shouji[index]);
                if (index != -1) {
                    this.yxx.splice(index, 1);
                    this.shouji.splice(index1, 1);
                    this.information.qualityType = this.shouji.join(',');
                }
            }
            if (this.information.qualityType == '') {
                this.information.qualityType = null;
            }
            this.$emit('watchData', this.information);
        },
        //客户资金需求标签选择
        dk(e) {
            if (bqxz(e, this.dked, this.yxx) === 1) {
                this.isShow1 = NaN;
                this.information.fundNeedsScopeType = null;
            } else {
                this.isShow1 = e;
                this.information.fundNeedsScopeType = this.dked[e].number;
            }
            this.$emit('watchData', this.information);
        },
        //客户状态标签选择
        qw(e) {
            if (bqxz(e, this.wqr, this.yxx) === 1) {
                this.isShow2 = NaN;
                this.information.followStage = null;
            } else {
                this.isShow2 = e;
                this.information.followStage = this.wqr[e].number;
            }
            this.$emit('watchData', this.information);
        },
        //客户获得方式标签选择
        hd(e) {
            if (bqxz(e, this.hdfs, this.yxx) === 1) {
                this.isShow3 = NaN;
                this.information.getCategory = null;
            } else {
                this.isShow3 = e;
                this.information.getCategory = this.hdfs[e].number;
            }
            this.$emit('watchData', this.information);
        },
        //客户标签选择
        bqsj(e) {
            if (bqxz(e, this.bq, this.yxx) === 1) {
                this.isShow4 = NaN;
                this.information.unconfirmedTag = null;
            } else {
                this.isShow4 = e;
                this.information.unconfirmedTag = this.bq[e].number;
            }
            this.$emit('watchData', this.information);
        },
        //线索状态选择
        zt(e) {
            if (bqxz(e, this.xszt, this.yxx) === 1) {
                this.isShow5 = NaN;
                this.information.status = null;
            } else {
                this.isShow5 = e;
                this.information.status = this.xszt[e].number;
            }
            this.$emit('watchData', this.information);
        },
        //无效标签选择
        wx(e) {
            if (bqxz(e, this.wxbq, this.yxx) === 1) {
                this.isShow6 = NaN;
                this.information.invalidTag = null;
            } else {
                this.isShow6 = e;
                this.information.invalidTag = this.wxbq[e].number;
            }
            this.$emit('watchData', this.information);
        },
    },
};
</script>
<style lang="less" scoped>
@import '../../css/table.less';
</style>